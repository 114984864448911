<template>  
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false" :showCloseIcon="true" @hide="closeForm" position="right" :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'"> 
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data"> 
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{module_name}}</h3> 
                        <p>You can add or update {{module_name}} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">  
                        <div class="col-7 lg:col-7 sm:col-6 md:col-7">
                            <div> 
                                <!-- <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name"  type="text" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="name" /> 
                                </div> -->
                                <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown CompanyLoading :filter="true" :showClear="true"  v-model="form.company_id" :options="companies" optionLabel="name" optionValue="id"  :placeholder="CompanyLoading ? 'Loading...' : 'Select Company'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div> 
                                <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Frequency For</label>
                                    <Dropdown @change="getVariableDescription($event)" automationVariableLoading :filter="true" :showClear="true"  v-model="form.automation_variable" :options="automationVariables" optionLabel="name" optionValue="id"  :placeholder="automationVariableLoading ? 'Loading...' : 'Select Value'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="automation_variable" />
                                </div> 
                                <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Email Templates</label>
                                    <Dropdown templatesLoading :filter="true" :showClear="true"  v-model="form.email_template" :options="emailTemplates" optionLabel="name" optionValue="id"  :placeholder="templatesLoading ? 'Loading...' : 'Select Email Template'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="email_template" />
                                </div>
                                <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> 
                                    <label><span class="pi pi-star-fill important mr-1"></span>Frequency Method</label>
                                    <Dropdown :filter="true" :showClear="true"  v-model="form.frequency_approach" :options="frequecnyApproaches" optionLabel="name" optionValue="id"  :placeholder="'Loading...'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="frequency_approach" />
                                </div>    
                                <!-- <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> </div> -->
                                <div v-if="form.frequency_approach == 'Manual'">
                                    <Divider /> 
                                    <div class="col-4 lg:col-4 sm:col-4 mb-2" style="float:left"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>After every </label>
                                        <InputNumber id="send_times" placeholder="Add Number" showButtons="true" :min="0" class=" mr-2 full-width"  v-model="form.frequency_type_repititions" />
                                        <HasError class="p-error" :form="form" field="frequency_type_repititions" /> 
                                    </div> 
                                    <div class="col-4 lg:col-4 sm:col-4 mb-2" style="float:left"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>Frequency Type</label>
                                        <Dropdown  :filter="true" :showClear="true"  v-model="form.frequency_type" :options="frequecnyTypes" optionLabel="name" optionValue="id"  :placeholder="'Select Frequency Type'" class="full-width"/>
                                        <HasError class="p-error" :form="form" field="frequency_type" />
                                    </div> 
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" style="float:left">  
                                        <p>{{form.description}}</p>
                                    </div>  
                                    <div class="col-4 lg:col-4 sm:col-4 mb-2" style="float:left"> 
                                        <label><span class="pi pi-star-fill important mr-1"></span>No of total repititons of above schedule</label>
                                        <InputNumber id="send_times" placeholder="Add Number" showButtons="true" :min="0" class=" mr-2 full-width"  v-model="form.frequency" />
                                        <HasError class="p-error" :form="form" field="frequency" /> 
                                    </div>  
                                </div>
                                <Divider />
                                <div class="col-4 lg:col-4 sm:col-12 mb-2" style="float:left"> 
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name" optionValue="id" placeholder="Select Status" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="status" />
                                </div> 
                                
                            </div>  
                        </div>   
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check" @click="save()" class="p-button-raised p-button-lg p-button-success mr-2"/>
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check" @click="saveAndClose=true, save()" class="p-button-raised p-button-lg p-button-info mr-2"/>
                                <Button type="button" label="Reset" icon="pi pi-refresh" class="p-right p-button-raised p-button-lg p-button-warning mr-2" @click="resetForm()"/>
                            </template>
                            <template v-slot:end> 
                                <Button type="button" label="Cancel" icon="pi pi-times" class="p-right p-button-raised p-button-lg p-button-danger mr-2" @click="closeForm"/>
                            </template> 
                        </Toolbar>   
                    </div>
                </div> 
            </form>
        </Sidebar> 
    </BlockUI>
    
</template>
<script> 
 
 
export default {
    data() {
			return {  
                form: new this.Form({ 
                    id: '', 
                    company_id: 0, 
                    email_template: 0,  
                    frequency: 0,  
                    frequency_type: 'Day',  
                    frequency_approach: '',   
                    frequency_type_repititions: 0,   
                    status:1, 
                    automation_variable: 0,  
                    description:''
                }),   
                variable_description:'its description',
                templatesLoading: false,
                emailTemplates:[],
                automationVariables:[],
                CompanyLoading:false,
                automationVariableLoading:false,
                module_name:'Automation Settings',  
                isSaving:false,  
                saveAndClose:false, 
                recId:0,
                loadingComponent:true,
                formVisible:false, 
                companies:[], 
                frequecnyTypes: [{name:'Minute', id : 'Minute'},{name:'Hour', id : 'Hour'},{name:'Day', id : 'Day'},{name:'Week', id : 'Week'},{name:'Month', id : 'Month'},{name:'Year', id : 'Year'}], 
                frequecnyApproaches: [{name:'Auto', id : 'Auto'},{name:'Manual', id : 'Manual'}], 
                
                 
            }
		},   
        props:{
            showForm : {
                type : Boolean,
                required : true,
            },
            recordId :{
                type : Number,
            },
        },
        components:{
            // 'editor': Editor
        },
		created() {   
		},
        computed: { 
        },
		mounted() {  
            this.resetForm();
            this.formVisible = this.showForm; 
            this.recId = this.recordId;   
            this.getAllRequestData();  
		},
		methods: {
            getVariableDescription($event){
                if(this.automationVariables.length && $event.value){
                    this.automationVariables.forEach( (variable) => {
                        if(variable.id == $event.value){
                            this.form.description = variable.description;
                        }
                    })
                }   
            },  
             getAllRequestData(){   
                this.$axios.all([this.updateRecord()])   
                .then(() => {  
                    this.getCompanies();   
                }) 
                .then(() => {   
                    this.getAutomationVariables();   
                    this.getEmailTemplates();   
                })  
                .then(() => { 
                    this.blockComponent = false;     
                }).catch(() => {     
                })
                .finally(() => {  
                }); 
            },
            async getCompanies(){ 
                var Vthis = this;
                Vthis.CompanyLoading = true;
                await this.axios.post('getAllCompanies')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.companies = response.data;   
                        if(this.form.company_id==0){
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    Vthis.CompanyLoading = false;
                })  
            }, 
              
            resetForm(){
                this.form.reset();
            },
            
            getEmailTemplates(){ 
                var Vthis = this;
                this.templatesLoading = true;
                this.axios.post('getAllEmailTemplates')
                .then( (response) => {  
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.emailTemplates = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.templatesLoading = false; 
                })  
            },
            getAutomationVariables(){ 
                var Vthis = this;
                this.automationVariableLoading = true;
                this.axios.post('getAllAutomationVariables')
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){  
                        this.automationVariables = response.data;   
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                    this.automationVariableLoading = false; 
                })  
            },
            async save(){   
                var Vthis = this; 
                this.isSaving = true;
                // this.form.description = this.variable_description;
                await this.form
                .post(Vthis.$baseurl+'api/saveAutomationSettings', Vthis.form)
                .then((response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        if(!this.recId){
                            //  this.getRecords();
                        }
                        // this.getRecords();
                        if(this.saveAndClose){   
                            //  this.getRecords();
                            this.saveAndClose = false;
                            setTimeout( () => {this.closeForm();},1000);
                            // this.closeForm();
                        }   
                        Vthis.showMessage('Saved successfully','success',); 
                    }
                })
                .catch((error) => {   
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false; 
                }) 
            }, 
            loadForm(){
                this.resetForm(); 
            },
            closeForm(){
                this.$emit('formClose',true); 
            },
             
            async updateRecord(){ 
                if(this.recId > 0){
                    var Vthis = this;
                    this.loading = true;
                    await this.axios.post('getAllAutomationSettings',{id:this.recId})
                    .then( (response) => { 
                        let responseStatus = this.printResponseResult(response,false); 
                        if(responseStatus == 200){    
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]); 
                        }
                    })
                    .catch((error) => {    
                        this.printResponseResult(error);
                    })
                    .finally(() => { 
                        Vthis.loading = false;
                    })  
                }
            },   
            
            
		},
        watch : {
             
        }
}
</script>
 
