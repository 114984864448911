<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>
    <div class="grid">   
        <div class="col-12"> 
            <div class="card"> 
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                   </template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar> 
            </div>
        </div>
        
		<div class="col-6" v-for="(rec,key) in records" :key="key"> 

        
			<div class="card overview-box flex flex-column pt-2 " :class="rec.status == 'Blocked' ? 'bg-red' : ''">
				<div class="flex align-items-center muted-text"> 
					<!-- <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}"></h6> -->
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text p-button-plain"  :recordId="rec.id" @click="toggleRecordMenu"></Button>
						<Menu ref="menu" :popup="true" :model="actions" ></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column">
                        <h2>{{rec.automation_variable_name}}</h2>
						 <h3 style="font-weight: normal;">The email template <strong>{{rec.template_name}}</strong> will be sent after every <strong>{{rec.frequency_type_repititions}} {{rec.frequency_type}}</strong> {{rec.description}}</h3>
					</div> 
				</div>
			</div>
		 

			<!-- <div class="card">   
                 
                    <div class="mt-0 mb-0">  
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12">
                            <h3>{{rec.automation_variable_name}}</h3>
                        </div>
                        <div class="col-12 lg:col-12 sm:col-12 md:col-12 p-0"> 
                             
                        </div>  
                        <Divider /> 
                    </div>  
            </div> -->
        </div> 
        <ConfirmDialog group="dialog" />
        <AutomationSettingForm v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :recordId="selectedRecordId" :showForm="true" /> 
        <ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
     
    </div>
</template>
   
<script>
import AutomationSettingForm from '../../components/SystemSettings/AutomationSettingForm.vue';
import ModuleHelp from '../../components/Help/ModuleHelp.vue';
export default {
		data() { 
            return {   
                moduleId : 56, 
                module_name : 'Automation',  
                records : null,  
                isSaving:false, 
                actions: [],  
                showHelp : false,  
				filters: null, 
				loading: true,  
				selectedRecords: null,
				columns: null,
                selectedColumns: null, 
                showForm:false,
                selectedRecordId: 0,  
                saveAndClose:false, 
            }
            
		},
        created(){
            if(this.checkPermission(this.moduleId,'update')){
                this.actions.push({
						label: 'Update',
						icon: 'pi pi-refresh',
                        command: () => {
                            this.getClickedRecord('update');
                        }
					});
            }
            if(this.checkPermission(this.moduleId,'delete')){
                this.actions.push({
						label: 'Delete',
						icon: 'pi pi-times',
                        command: () => {
                            this.getClickedRecord('delete');
                        }
					});
            }     
        },
        components:{
            AutomationSettingForm,
            ModuleHelp
        },
        mounted(){   
            this.getRecords();
        },
        methods:{    
            getClickedRecord(action){
                if(action == 'delete'){ 
                    this.deleteRecord(); 
                }
                else if(action == 'view'){
                    this.viewRecord();
                }  
                else if(action == 'update'){
                    this.showForm = true; 
                }
            },
            loadForm(){ 
                this.selectedRecordId = 0;
                this.showForm = true;
            },
            closeForm(){ 
                if(!this.selectedRecordId){
                    this.getRecords();
                }
                this.showForm = false;
            }, 
            getRecords(){ 
                var Vthis = this;
                this.loading = true;
                this.axios.post('getAllAutomationSettings')
                .then( (response) => { 
                    let responseStatus = this.printResponseResult(response,false); 
                    if(responseStatus == 200){ 
                        this.records = response.data;  
                        this.loading = false; 
                    }
                })
                .catch((error) => {    
                    this.printResponseResult(error);
                })
                .finally(() => { 
                })  
            },
            deleteRecord(){ 
                var Vthis = this;
                this.$confirm.require({
                    group: 'dialog',
                    header: 'Confirmation',
                    message: 'Are you sure you want to delete?',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => { 
                        Vthis.loading = true;  
                        let DeleteAbleRecords = [];
                         
                        if(Vthis.selectedRecords){
                            Vthis.selectedRecordId = '';
                            Vthis.selectedRecords.forEach((data) => {
                                DeleteAbleRecords.push(data.id);
                            })
                        } 
                        else{
                            DeleteAbleRecords.push(Vthis.selectedRecordId);
                        }  

                        Vthis.axios.post('deleteAutomationSettings',{id:DeleteAbleRecords})
                        .then( (response) => {   
                            let responseStatus = this.printResponseResult(response,false); 
                            if(responseStatus == 200){
                                Vthis.getRecords();
                                Vthis.showMessage('Record deleted successfully','success'); 
                            }
                        })
                        .catch((error) => {   
                            this.printResponseResult(error);
                        })
                        .finally(() => { 
                            Vthis.loading = false;
                        })
                    },
                    reject: () => {
                        // this.loading = false;
                    }
                }); 
            },
            toggleRecordMenu(event) {
                this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
				this.$refs.menu.toggle(event);
			},
            
        }
}
</script>